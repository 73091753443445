import { Box, CardActions } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import React from "react";


const useStyles = makeStyles({
  card: {
    display: "flex",
    flexDirection: 'column',
    height: '100%'
  },
  cardDetails: {
    flex: 1,
    flexGrow: 1,
  },
  cardMedia: {
    width: '100%',
  },
});

export default function ProjectCard(props) {
  const classes = useStyles();
  const { project } = props;
  return (
        <Card className={classes.card}>
        <CardMedia
              className={classes.cardMedia}
              component="img"
              title={project.frontmatter.title}
              src={project.frontmatter.image}
            />
            <CardContent className={classes.cardDetails}>
              <Typography component="h2" variant="h5">
                {project.frontmatter.title}
              </Typography>
              <Typography variant="subtitle1" paragraph>
                {project.excerpt}
              </Typography>
            </CardContent>
            <CardActions>
                    {/* <Button size="small" onClick={() => navigate(project.frontmatter.slug)}>Read more...</Button> */}
                    <Box flexGrow={1}></Box>

                    <Button size="small" variant="outlined" href={project.frontmatter.externalLink}>Open</Button>
            </CardActions>            
        </Card>
  );
}

ProjectCard.propTypes = {
  project: PropTypes.object,
};
